<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
    </section>
    <section class="uk-margin">
      <div  v-for="(other, oKey) in others" :key="oKey">
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
        >
          <div class="uk-width-1-2 uk-margin-auto-vertical">{{ other.label }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <!-- Text Area Input -->
              <div v-if="other.type === 'textarea'" class="uk-width-expand">
                <textarea v-model="form[other.value]" class="uk-textarea uk-form-small" />
              </div>
              <!-- Checkbox Input -->
              <div v-else-if="other.type === 'checkbox'" class="uk-width-expand">
                <!-- Preferences -->
                <section class="uk-flex uk-flex-left uk-flex-wrap">
                  <div
                    v-for="preference in preferences"
                    :key="preference._id"
                    class="uk-margin-small-bottom uk-margin-small-right preference uk-flex uk-flex-middle"
                    :class="selectedPreferences(preference) ? 'selected-preference uk-text-bolder' : ''"
                    @click="toogleSelected(preference)"
                  >
                    <span>{{ preference.name }}</span>
                    <span
                      v-if="selectedPreferences(preference)"
                      class="uk-margin-small-left"
                    >
                      <img :src="`${images}/check-circle-d-green.svg`" alt="" width="15" />
                    </span>
                  </div>
                </section>
              </div>
              <!-- Text Input -->
              <div v-else class="uk-width-expand">
                <!-- Skills -->
                <section 
                  v-for="(skill, sKey) in form[other.value]"
                  :key="`sk-${sKey}`"
                >
                  <div class="uk-flex uk-flex-between uk-flex-middle">
                    <input
                      v-model="form[other.value][sKey].name"
                      class="uk-input uk-margin-small-bottom uk-margin-small-right"
                      type="text"
                    >
                    <div class="uk-margin-small-bottom">
                      <img
                        :src="`${images}/trash.png`"
                        alt=""
                        width="20"
                        style="cursor: pointer"
                        @click="form.skills.splice(sKey, 1)"
                      />
                    </div>
                  </div>
                  <!-- Validation Error Skill -->
                  <div
                    v-if="errorsD.skills[sKey] && errorsD.skills[sKey].name"
                    class="uk-text-danger"
                  >
                    {{ errorsD.skills[sKey].name }}
                  </div>
                </section>
                <!-- Button Add Skill -->
                <button
                    class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-text-bolder uk-flex uk-flex-middle uk-flex-center"
                    style="border-color: #0ABAB5; color: #0ABAB5"
                    @click="addSkils"
                >
                    <img :src="`${images}/add.png`" alt="" width="20">
                    <div class="uk-margin-small-left">Tambah Keterampilan</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr v-if="oKey + 1 < others.length">
      </div>

      <!-- Experience -->
      <h5 class="uk-text-bold">Pengalaman Kerja</h5>
      <button
          class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-text-bolder uk-flex uk-flex-middle uk-flex-center uk-margin-small-bottom"
          style="border-color: #0ABAB5; color: #0ABAB5"
          @click="addExperience"
      >
          <img :src="`${images}/add.png`" alt="" width="20">
          <div class="uk-margin-small-left">Tambah Pengalaman Kerja</div>
      </button>
      <section v-for="(exp, dKey) in form.work_experiences" :key="`d-${dKey}`">
        <div v-for="(edu, eKey) in details" :key="eKey">
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
            v-for="(ed, edKey) in edu"
            :key="edKey"
          >
            <div class="uk-width-1-2">{{ ed.label }}</div>
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <!-- DATE INPUT -->
                <div v-if="ed.type === 'date'" class="uk-width-expand">
                  <div class="uk-flex uk-flex-middle uk-flex-left">
                    <datetime
                        name="date"
                        id="date"
                        input-class="uk-input uk-border-rounded"
                        type="date"
                        format="dd LLL yyyy"
                        :max-datetime="datetime.now().toISODate()"
                        v-model="form.work_experiences[dKey].start_date"
                    ></datetime>
                    <span class="uk-margin-small-left uk-margin-small-right">-</span>
                    <datetime
                        name="date"
                        id="date"
                        input-class="uk-input uk-border-rounded"
                        type="date"
                        format="dd LLL yyyy"
                        :max-datetime="datetime.now().toISODate()"
                        :disabled="form.work_experiences[dKey].present"
                        v-model="form.work_experiences[dKey].end_date"
                    ></datetime>
                  </div>
                  <div class="uk-margin-small-top">
                    <label>
                      <input
                        v-model="form.work_experiences[dKey].present"
                        class="uk-checkbox"
                        type="checkbox"
                        @change="(form.work_experiences[dKey].end_date = null)"
                      > Masih bekerja disini
                    </label>
                  </div>
                </div>
                <!-- TEXT INPUT -->
                <div v-else class="uk-width-expand">
                  <input
                    v-model="form.work_experiences[dKey][ed.value]"
                    class="uk-input"
                    type="text"
                  >
                </div>
              </div>
              <div
                v-if="errorsD.work_experiences[dKey] && errorsD.work_experiences[dKey][ed.value]"
                class="uk-text-danger"
              >
                {{ errorsD.work_experiences[dKey][ed.value] }}
              </div>
            </div>
          </div>
          <!-- Delete Button -->
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
          >
            <div class="uk-width-1-2" />
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-expand">
                  <button
                      class="uk-button uk-button-default uk-border-rounded uk-margin-right"
                      style="border-color: #0ABAB5; color: #0ABAB5"
                      @click="form.work_experiences.splice(dKey, 1)"
                  >
                      Delete
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr v-if="dKey + 1 < form.work_experiences.length">
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {PREFIX_IMAGE} from '@/utils/constant';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { notificationSuccess } from '@/utils/notification';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    components: { Datetime },
    data() {
        return {
            datetime: DateTime,
            images: PREFIX_IMAGE,
            isLoading: false,
            preferences: [],
            others: [
                { label: 'Tentang Saya', value: 'description', type: "textarea" },
                { label: 'Keterampilan', value: 'skills', type: "text" },
                { label: 'Preferensi', value: 'job_preferences', type: "checkbox" },
            ],
            details: [
                [
                    { label: "Posisi", value: "title", type: "text" },
                    { label: "Perusahaan", value: "company_name", type: "text" },
                    { label: "Durasi Bekerja", value: "periode", type: "date" },
                    { label: "Deskripsi", value: "description", type: "text" },
                ]
            ],
            form: { ...this.$props.data },
            errorsD: {
                work_experiences: [],
                skills: [],
            },
        };
    },

    async mounted() {
        this.getPreferences();
    },

    methods: {
        ...mapActions({
            getJobPreferences: 'option_data/getJobPreferences',
            patchExperience: 'mitra/patchExperience',
        }),

        async getPreferences() {
            this.preferences = await this.getJobPreferences();
        },

        selectedPreferences(prf) {
            const prefer = this.form.job_preferences.find(data => data._id === prf._id);

            if (prefer) {
                return true;
            } else {
                return false;
            }
        },

        toogleSelected(prf) {
            const prefer = this.form.job_preferences.findIndex(data => data._id === prf._id);

            if (prefer > -1) {
                this.form.job_preferences.splice(prefer, 1);
            } else {
                this.form.job_preferences.push(prf);
            }
        },

        addSkils() {
            this.form.skills.push({ name: null, sort: this.form.skills.length + 1 });
        },

        addExperience() {
            this.form.work_experiences.push({
                sort: this.form.work_experiences.length + 1,
                company_name: null,
                title: null,
                description: null,
                start_date: null,
                end_date: null,
                present: false
            });
        },

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "description": !this.form.description || this.form.description === '' ? null : this.form.description,
                "skills": this.form.skills,
                "work_experiences": this.form.work_experiences.map(data => {
                    return {
                        ...data,
                        description: !data.description || data.description === '' ? null : data.description
                    };
                }),
                "job_preference_ids": this.form.job_preferences.map(data => data._id)
            };

            await this.patchExperience({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        validateData() {
            let isValidated = true;
            this.errorsD = {
                work_experiences: [],
                skills: [],
            };
            
            this.form.skills.forEach((data, dKey) => {
                if (!data.name) {
                    if (!this.errorsD.skills[dKey]) {
                        this.errorsD.skills[dKey] = { name: "keterampilan harus diisi" };
                    } else {
                        this.errorsD.skills[dKey]["name"] = "keterampilan harus diisi";
                    }
                    
                    isValidated = false;
                }
            });
            
            this.form.work_experiences.forEach((data, dKey) => {
                if (!data.company_name) {
                    if (!this.errorsD.work_experiences[dKey]) {
                        this.errorsD.work_experiences[dKey] = { company_name: "perusahaan harus diisi" };
                    } else {
                        this.errorsD.work_experiences[dKey]["company_name"] = "perusahaan harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (!data.title) {
                    if (!this.errorsD.work_experiences[dKey]) {
                        this.errorsD.work_experiences[dKey] = { title: "posisi harus diisi" };
                    } else {
                        this.errorsD.work_experiences[dKey]["title"] = "posisi harus diisi";
                    }
                    
                    isValidated = false;
                }

                if (!data.start_date) {
                    if (!this.errorsD.work_experiences[dKey]) {
                        this.errorsD.work_experiences[dKey] = { periode: "tanggal mulai harus diisi" };
                    } else {
                        this.errorsD.work_experiences[dKey]["periode"] = "tanggal mulai harus diisi";
                    }

                    isValidated = false;
                }

                if (!data.present && !data.end_date) {
                    if (!this.errorsD.work_experiences[dKey]) {
                        this.errorsD.work_experiences[dKey] = { periode: "tanggal berakhir harus diisi" };
                    } else {
                        this.errorsD.work_experiences[dKey]["periode"] = "tanggal berakhir harus diisi";
                    }

                    isValidated = false;
                }
            });

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }

  .selected-preference {
    background: #D7EBEA !important;
    color: #02928D !important;
  }

  .preference {
    background: #EEEEEE;
    border-radius: 18px;
    padding: 5px 10px;
    color: #212121;
    font-size: 12px;
    cursor: pointer;
  }
</style>
