<template>
  <div>
    <section>
        <button
            v-if="$can('EDIT', 'worker-mitra')"
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="$emit('action', true)"
        >
            Edit
        </button>
    </section>
    <section class="uk-margin">
      <div  v-for="(other, oKey) in others" :key="oKey">
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
        >
          <div class="uk-width-1-2">{{ other.label }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto">:&nbsp;</div>
              <div class="uk-width-expand">{{ data[other.value] instanceof Array ? data[other.value].join(", ") : data[other.value] }}</div>
            </div>
          </div>
        </div>
        <hr v-if="oKey + 1 < others.length">
      </div>

      <h5 class="uk-text-bold">Pengalaman Kerja</h5>
      <section v-for="(exp, dKey) in data.work_experiences" :key="`d-${dKey}`">
        <div v-for="(edu, eKey) in details" :key="eKey">
          <div
            class="uk-grid-collapse"
            style="padding: .2rem 0"
            uk-grid
            v-for="(ed, edKey) in edu"
            :key="edKey"
          >
            <div class="uk-width-1-2">{{ ed.label }}</div>
            <div class="uk-width-1-2">
              <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-auto">:&nbsp;</div>
                <div v-if="ed.value === 'periode'" class="uk-width-expand">
                  {{ formatter.dateFormatSlash(exp.start_date) }} - {{ exp.end_date ? formatter.dateFormatSlash(exp.end_date) : "Sekarang" }}
                </div>
                <div v-else class="uk-width-expand">{{ exp[ed.value] }}</div>
              </div>
            </div>
          </div>
          <hr v-if="dKey + 1 < data.work_experiences.length">
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            isLoading: false,
            others: [
                { label: 'Tentang Saya', value: 'description' },
                { label: 'Keterampilan', value: 'skills_2' },
                { label: 'Preferensi', value: 'job_preferences_2' },
            ],
            details: [
                [
                    { label: "Posisi", value: "title" },
                    { label: "Perusahaan", value: "company_name" },
                    { label: "Durasi Bekerja", value: "periode" },
                    { label: "Deskripsi", value: "description" },
                ]
            ],
            data: null,
            formatter: formatter,
        };
    },

    async mounted() {
        this.getMitraExperience();
    },

    methods: {
        ...mapActions({
            getExperience: 'mitra/getExperience',
        }),

        async getMitraExperience() {
            this.isLoading = true;
            const data = await this.getExperience(this.$route.params.mitra_id);

            if (data.result) {
                this.data = {
                    ...data.result,
                    skills_2: data.result.skills.map(skill => skill.name),
                    job_preferences_2: data.result.job_preferences.map(preference => preference.name)
                };
                this.$emit("passData", this.data);
              
            }

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
